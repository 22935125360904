import { useClearSnooze, useSnooze } from '@/api/leads/leads/snoozeLead';
import { useClearWait } from '@/api/leads/leads/updateLead';
import { ClearWaitButton } from '@/components/Conversations/ClearWaitButton';
import { ConversationThread } from '@/components/Conversations/ConversationThread';
import { SendMessage } from '@/components/Conversations/SendMessage';
import { SnoozeButton } from '@/components/Conversations/SnoozeButton';
import NoResults from '@/components/Elements/NoResults/NoResults';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import { useContext } from 'react';

export const MessagesTab = () => {
  const { mutate: clearWait } = useClearWait();
  const { mutate: snooze } = useSnooze();
  const { mutate: clearSnooze } = useClearSnooze();
  const { lead, temporaryTextMessage, setTemporaryTextMessage } = useContext(LeadContext);

  const handleSnooze = (snooze_until: string) => {
    if (!lead) return;
    snooze({ id: lead.id, snooze_until });
  };

  const handleClearSnooze = () => {
    if (!lead) return;
    clearSnooze({ id: lead.id });
  };

  const handleClearWait = () => {
    if (!lead) return;
    clearWait({ id: lead.id });
  };

  if (!lead) return <>Loading...</>;

  return (
    <div className="-mr-5 -mt-3 mb-16">
      {lead.customer ? (
        <>
          <ConversationThread customerId={lead.customer.id} />
          <div className="px-2">
            {lead.customer && (
              <SendMessage
                customer={lead.customer}
                context={lead}
                contextType="lead"
                defaultMessage={temporaryTextMessage}
                setDefaultMessage={setTemporaryTextMessage}
              />
            )}
          </div>
          <div className="flex flex-row justify-end space-x-2 my-3 mx-2">
            <SnoozeButton
              snoozedUntil={lead.snooze_until}
              onSnooze={handleSnooze}
              onClearSnooze={handleClearSnooze}
            />
            <ClearWaitButton onClick={handleClearWait} />
          </div>
        </>
      ) : (
        <NoResults label="customer" />
      )}
    </div>
  );
};
