import { Customer, Event, Lead, MessageTemplate, User } from '@/types';

type MessageTemplateArguments = {
  template: MessageTemplate;
  user: User | null | undefined;
  customer: Customer;
} & ({ context: Lead; contextType: 'lead' } | { context: Event; contextType: 'event' });

const prepareAddress = (context: Lead | Event) => {
  let address = '';
  address += `${context.address} `;
  if (context.address_line2) {
    address += `${context.address_line2} `;
  }
  address += `${context.city}, ${context.state} ${context.zipcode}`;

  return address.toLocaleUpperCase();
};

const prepareVip = (context: Lead | Event) => {
  let vip = '';
  if (context.vips?.length > 0) {
    if (context.vips.length === 1) {
      vip = context.vips[0].first_name ?? '';
    } else if (context.vips.length === 2) {
      vip = `${context.vips[0].first_name} and ${context.vips[1].first_name}`;
    } else {
      // comma separated list of VIPs, except for last which is 'and'
      vip = context.vips.map((vip) => vip.first_name).join(', ');
      vip = vip.replace(/,([^,]*)$/, ' and$1');
    }
  }

  return vip;
};

const prepareStaff = (context: Event) => {
  let staff = '';
  if (context.activities?.length > 0) {
    if (context.activities[0].staff?.length > 0) {
      staff = context.activities[0].staff[0].first_name ?? '';
    }
  }

  return staff;
};

const prepareDateWanted = (context: Lead | Event, contextType: 'lead' | 'event') => {
  let wanted = '';
  if (contextType === 'lead') {
    const lead = context as Lead;
    if (lead.date_wanted) {
      const parts = lead.date_wanted.split('-');
      if (parts.length === 3) {
        wanted = `${parts[1]}/${parts[2]}/${parts[0]}`;
      }
    }
  } else {
    const event = context as Event;
    if (event.start_at) {
      const parts = event.start_at.split(' ')[0].split('-');
      if (parts.length === 3) {
        wanted = `${parts[1]}/${parts[2]}/${parts[0]}`;
      }
    }
  }

  return wanted;
};

export const applyMessageTemplateVariables = ({
  template,
  user,
  customer,
  context,
  contextType,
}: MessageTemplateArguments) => {
  const message = template.message
    .replace(/\[agent\.first_name\]/g, user?.first_name ?? 'Kai')
    .replace(/\[customer\.first_name\]/g, customer.first_name ?? '')
    .replace(/\[event_address\]/g, prepareAddress(context))
    .replace(/\[vip.first_name\]/g, prepareVip(context))
    .replace(
      /\[staff.first_name\]/g,
      contextType === 'lead' ? 'your GameCoach' : prepareStaff(context),
    )
    .replace(/\[date_wanted\]/g, prepareDateWanted(context, contextType));

  return message;
};
