import { useCreateMessageTemplate } from '@/api/settings/message-templates/createMessageTemplate';
import { useDeleteMessageTemplate } from '@/api/settings/message-templates/deleteMessageTemplate';
import { useMessageTemplates } from '@/api/settings/message-templates/getMessageTemplates';
import { useUpdateMessageTemplate } from '@/api/settings/message-templates/updateMessageTemplate';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/elements/dialog';
import { MESSAGE_TEMPLATE_CATEGORIES } from '@/config';
import { LocationContext } from '@/providers/location';
import { MessageTemplate, ModelID } from '@/types';
import { cn } from '@/utils/format';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogProps } from '@radix-ui/react-dialog';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ConfirmationDialog } from '../Elements';
import List from '../Elements/List/List';
import { FormInput, FormSelect, FormTextarea } from '../Form';
import { Button } from '../ui/elements/button';
import { Form } from '../ui/form/form';

type MessageTemplatesDialogProps = DialogProps & {
  onSelect: (template: MessageTemplate) => void;
};

const schema = z.object({
  category: z.string(),
  name: z.string().min(3, 'Required'),
  message: z.string().min(3, 'Required'),
});

const defaultValues = { category: 'sales', name: '', message: '' };

export const MessageTemplatesDialog = ({ onSelect, ...props }: MessageTemplatesDialogProps) => {
  const { activeLocationId } = useContext(LocationContext);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [editMessageId, setEditMessageId] = useState<ModelID | null>(null);

  const { data: messageTemplates } = useMessageTemplates({ location: activeLocationId });
  const { mutate: createTemplate } = useCreateMessageTemplate();
  const { mutate: updateTemplate } = useUpdateMessageTemplate();
  const { mutate: deleteTemplate } = useDeleteMessageTemplate({
    onSuccess: () => {
      setEditMode(false);
      setEditMessageId(null);
    },
  });

  const handleSelect = (template: MessageTemplate) => {
    onSelect(template);
    handleClose();
  };

  const handleClose = () => {
    if (props.onOpenChange) props.onOpenChange(!props.open);
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const handleSelectForEdit = (template: MessageTemplate) => {
    setEditMode(true);
    setEditMessageId(template.id);
    form.reset(template);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditMessageId(null);
    form.reset(defaultValues);
  };

  const handleSubmitTemplate = (values: z.infer<typeof schema>) => {
    if (!editMode || !activeLocationId) return;

    if (editMessageId) {
      updateTemplate(
        { id: editMessageId, data: { franchise_id: activeLocationId, ...values } },
        {
          onSuccess: handleCancelEdit,
        },
      );
    } else {
      createTemplate(
        { data: { franchise_id: activeLocationId, ...values } },
        {
          onSuccess: handleCancelEdit,
        },
      );
    }
  };

  const handleDeleteTemplate = (template: MessageTemplate) => {
    deleteTemplate({ id: template.id });
  };

  const templateOptionsByCategory = messageTemplates
    ?.sort((a, b) => a.category.localeCompare(b.category))
    ?.reduce((acc, template) => {
      if (!acc[template.category]) {
        acc[template.category] = [];
      }
      acc[template.category].push(template);
      return acc;
    }, {} as Record<string, typeof messageTemplates>);

  return (
    <Dialog {...props} onOpenChange={handleClose}>
      <DialogContent className="w-full sm:max-w-4xl">
        <DialogHeader>
          <DialogTitle>
            {editMode ? (
              <>Edit Message Template</>
            ) : (
              <>
                Choose a Message Template: <span className="capitalize">{selectedCategory}</span>
              </>
            )}
          </DialogTitle>
        </DialogHeader>

        {editMode ? (
          <>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(handleSubmitTemplate)} className="space-y-3">
                <FormSelect
                  control={form.control}
                  name="category"
                  label="Category"
                  options={MESSAGE_TEMPLATE_CATEGORIES}
                />
                <FormInput control={form.control} name="name" label="Name" />
                <FormTextarea
                  control={form.control}
                  name="message"
                  label="Message"
                  description=""
                />

                <div className="flex flex-row justify-between">
                  <Button type="button" variant="outline" onClick={handleCancelEdit}>
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </div>
              </form>
              <span className="text-sm text-gray-600 py-0.5 px-1">
                <p>
                  You may include placeholder values which will automatically be replaced by info
                  from the customer/lead/event record. The currently supported placeholders are:
                </p>
                <ul className="space-x-2 my-2">
                  <li className="inline-block bg-gray-200 rounded px-0.5">[agent.first_name]</li>
                  <li className="inline-block bg-gray-200 rounded px-0.5">[customer.first_name]</li>
                  <li className="inline-block bg-gray-200 rounded px-0.5">[date_wanted]</li>
                  <li className="inline-block bg-gray-200 rounded px-0.5">[event_address]</li>
                  <li className="inline-block bg-gray-200 rounded px-0.5">[vip.first_name]</li>
                  <li className="inline-block bg-gray-200 rounded px-0.5">[staff.first_name]</li>
                </ul>
                <p>
                  Use these exactly, including the square brackets.Example: 'Hi
                  <span className="inline-block bg-gray-200 rounded px-0.5">
                    [customer.first_name]
                  </span>
                  , we are looking forward to your event on{' '}
                  <span className="inline-block bg-gray-200 rounded px-0.5">[date_wanted]</span>!'
                </p>
              </span>
            </Form>
          </>
        ) : (
          <div className="flex flex-row space-x-2 h-96">
            <div className="basis-1/3 flex flex-col">
              {templateOptionsByCategory && (
                <List>
                  {Object.keys(templateOptionsByCategory).map((category, i) => (
                    <List.Item
                      key={`template-${category}-${i}`}
                      onClick={() => setSelectedCategory(category)}
                    >
                      <span
                        className={cn(
                          'capitalize',
                          selectedCategory === category && 'font-bold text-gt-green-600',
                        )}
                      >
                        {category}
                      </span>
                    </List.Item>
                  ))}
                </List>
              )}
              <div className="grow" />
              <div>
                <Button onClick={() => setEditMode(true)}>Add New Template</Button>
              </div>
            </div>
            <div className="basis-2/3 overflow-y-scroll">
              {selectedCategory && templateOptionsByCategory && (
                <List>
                  {templateOptionsByCategory[selectedCategory].map((template, i) => (
                    <List.Item
                      key={`template-${template.name}-${i}`}
                      className="flex-row grow justify-between"
                    >
                      <div onClick={() => handleSelect(template)} className="hover:cursor-pointer">
                        <span className="text-base capitalize">{template.name}</span>
                        <p className="text-sm font-normal">{template.message}</p>
                      </div>
                      {template.franchise_id === activeLocationId && (
                        <div className="flex flex-row space-x-1">
                          <span>
                            <Button
                              type="button"
                              variant="icon"
                              onClick={() => handleSelectForEdit(template)}
                            >
                              <PencilIcon className="h-4 w-4 m-1" />
                            </Button>
                          </span>
                          <span>
                            <ConfirmationDialog
                              triggerButton={
                                <Button type="button" variant="icon">
                                  <TrashIcon className="h-4 w-4 m-1" />
                                </Button>
                              }
                              confirmAction={() => handleDeleteTemplate(template)}
                              title={`Delete template called ${template.name}?`}
                              body="Are you sure you want to delete this template? This action cannot be undone."
                            />
                          </span>
                        </div>
                      )}
                    </List.Item>
                  ))}
                </List>
              )}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
