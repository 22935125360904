import { useClearSnooze, useSnooze } from '@/api/events/events/snoozeEvent';
import { useClearWait } from '@/api/events/events/updateEvent';
import { ClearWaitButton } from '@/components/Conversations/ClearWaitButton';
import { ConversationThread } from '@/components/Conversations/ConversationThread';
import { SendMessage } from '@/components/Conversations/SendMessage';
import { SnoozeButton } from '@/components/Conversations/SnoozeButton';
import NoResults from '@/components/Elements/NoResults/NoResults';
import { EventContext } from '@/features/events/contexts/EventContext';
import { useContext } from 'react';

export const MessagesTab = () => {
  const { event, temporaryTextMessage, setTemporaryTextMessage } = useContext(EventContext);
  const { mutate: clearWait } = useClearWait();
  const { mutate: snooze } = useSnooze();
  const { mutate: clearSnooze } = useClearSnooze();

  const handleSnooze = (snooze_until: string) => {
    if (!event) return;
    snooze({ id: event.id, snooze_until });
  };

  const handleClearSnooze = () => {
    if (!event) return;
    clearSnooze({ id: event.id });
  };

  const handleClearWait = () => {
    if (!event) return;
    clearWait({ id: event.id });
  };

  if (!event) return <>Loading...</>;

  return (
    <div>
      {event.customer ? (
        <>
          <ConversationThread customerId={event.customer.id} />
          <div>
            {event.customer && (
              <SendMessage
                customer={event.customer}
                context={event}
                contextType="event"
                defaultMessage={temporaryTextMessage}
                setDefaultMessage={setTemporaryTextMessage}
              />
            )}
          </div>
          <div className="flex flex-row justify-end space-x-2 my-3 mx-2">
            <SnoozeButton
              snoozedUntil={event.snooze_until}
              onSnooze={handleSnooze}
              onClearSnooze={handleClearSnooze}
            />
            <ClearWaitButton onClick={handleClearWait} />
          </div>
        </>
      ) : (
        <NoResults label="customer" />
      )}
    </div>
  );
};
